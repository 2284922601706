<template>
  <div class="property-wrapper" v-if="control">
    <ControlBaseProperties
      v-model="control"
      @setExpression="buildExpression($event)"
      :hasExpression="expression != ''"
    />
    <ControlSpecificPanel :control="control">
      <div class="form-group form-group-sm">
        <label for="">{{ $t("titles.form") }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="formName"
            ref="formName"
          />
          <div
            class="input-group-addon btn"
            @click.stop.prevent="
              formName = '';
              $refs.formName.focus();
            "
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
      <!-- data input does has fixed expression targeting the data id and can not be edited -->
      <div class="form-group form-group-sm">
        <label for="">{{ $t("expression") }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :value="expression"
            disabled
            style="background-color: whitesmoke"
          />
          <div
            class="input-group-addon btn"
            @click.stop.prevent="expression = ''"
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
      <label>{{ $t("controls") }} / {{ $tc("button", 2) }}</label>
      <DataValueInputToolbar v-model="inputToolBar" />
      <DataValueCommandStatus :control="control" />
      <TogglePanel :title="$t('control_status')">
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.errorStyle"
          :label="$t('synoptic.error_state')"
        />
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.focusStyle"
          :label="$t('synoptic.focus_state')"
        />
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.pendingStyle"
          :label="$t('synoptic.pending_state')"
        />
      </TogglePanel>
      <TogglePanel :title="$t('synoptic.text_list')" style="margin-top: 10px">
        <div class="form-group form-group-sm">
          <div class="input-group">
            <div class="input-group-addon">
              {{ $t("data_source") }}
            </div>
            <select v-model="dataSourceType" class="form-control">
              <option
                v-bind:value="item.type"
                v-for="(item, ix) in dataSources"
                v-bind:key="ix"
              >
                {{ $tc(item.label) }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="form-group form-group-sm form-group-no-margin"
          v-if="dataSourceType == 'global'"
        >
          <select v-model="dataSourceId" class="form-control" ref="globalList">
            <option
              v-bind:value="item.id"
              v-for="(item, ix) in globalLists"
              v-bind:key="ix"
            >
              {{ $tc(item.name) }}
            </option>
          </select>
        </div>
        <section v-if="stateImages" class="state-list">
          <div
            class="state-item"
            v-for="(item, index) in stateImages"
            :key="index"
            :class="{ open: isEnabled(index) }"
          >
            <div class="row">
              <div class="form-group form-group-sm form-group-min col-xs-12">
                <div
                  class="clicable"
                  @click.prevent.stop="toggle(index, $event)"
                >
                  <label
                    :for="`editor-label-${index}`"
                    class="clicable"
                    style="margin-bottom: 0"
                  >
                    <span style="vertical-align: text-bottom">
                      <span v-if="isEnabled(index)">
                        <span class="small" style="opacity: 0.5"
                          >{{ index + 1 }}.</span
                        >
                        {{ $t("text") }}
                      </span>
                      <span v-else>
                        {{ item.label || $t("text") }}
                      </span>
                    </span>
                  </label>
                  <div class="pull-right">
                    <span
                      class="btn btn-xs"
                      style="padding: 0 5px"
                      v-if="dataSourceType == 'local'"
                      @click.stop.prevent="removeStateImage(index)"
                      :title="$t('synoptic.remove_image')"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                    <span class="btn btn-xs" title="collapse">
                      <i class="fa fa-angle-up" v-if="isEnabled(index)"></i>
                      <i class="fa fa-angle-down" v-else></i>
                    </span>
                  </div>
                </div>
                <div class="input-group input-group-sm">
                  <input
                    v-if="isEnabled(index)"
                    type="text"
                    class="form-control"
                    v-model="item.label"
                    :id="`editor-label-${index}`"
                    :ref="`editor-label-${index}`"
                    :placeholder="$t('synoptic.insert_label')"
                    :disabled="dataSourceType != 'local'"
                  />
                  <div
                    class="input-group-addon no-padding"
                    v-if="isEnabled(index)"
                  >
                    <ColorPicker
                      v-model="item.backgroundColor"
                      v-bind:pickerStyle="{
                        left: '-191px'
                      }"
                      icon="background"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="isEnabled(index) && item.state != 'default'">
              <div class="form-group form-group-sm form-group-min col-xs-12">
                <label :for="`editor-state-${index}`">{{ $t("value") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="item.state"
                  :id="`editor-state-${index}`"
                  :placeholder="$t('synoptic.data_value')"
                  :disabled="dataSourceType != 'local'"
                />
              </div>
            </div>
          </div>
          <div style="margin: 10px 0 50px 0">
            <button
              type="button"
              class="btn btn-xs btn-primary"
              @click="addStateImage"
              v-if="dataSourceType == 'local'"
            >
              {{ $t("synoptic.new_text") }}
            </button>
          </div>
        </section>
      </TogglePanel>
    </ControlSpecificPanel>
  </div>
</template>

<script>
import ColorPicker from "@/components/editor/color-picker.vue";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import DataValueCommandStatus from "./data-value-command-status.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import MultiColorsSelector from "@/components/editor/multi-colors-selector";
import DataValueInputToolbar from "@/components/synoptic/property-editor/controls/data-value-input-toolbar.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
export default {
  name: "DataValueSelect",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    ColorPicker,
    DataValueCommandStatus,
    ControlSpecificPanel,
    MultiColorsSelector,
    DataValueInputToolbar,
    TogglePanel
  },
  data() {
    return {
      control: null,
      enabled: {},
      dataSourceType: "local",
      dataSourceId: "",
      stateImages: [],
      lastData: null,
      controlStatus: false
    };
  },
  computed: {
    dataSources() {
      let lst = [{ type: "local", label: "control" }];
      if (this.globalLists?.length) {
        if (this.lastData && this.lastData.text_list) {
          lst.push({ type: "data", label: "data" });
        }
        lst.push({ type: "global", label: "global" });
      }
      return lst;
    },
    equipmentData() {
      if (this.control?.data_id || 0) {
        return (
          (this.$store.getters["dashboard/extendedDataList"] || []).find(
            (i) => i.id == this.control.data_id
          ) || null
        );
      }
      return null;
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    inputToolBar: {
      set(value) {
        let widget = this?.control?.synopticComponent || {};
        this.$set(widget, "restoreButton", value.restoreButton);
        this.$set(widget, "saveButton", value.saveButton);
        this.$set(widget, "toolbarVisibility", value.visibility);
      },
      get() {
        let widget = this?.control?.synopticComponent || {};
        return {
          restoreButton: widget?.restoreButton || false,
          saveButton: widget?.saveButton || false,
          visibility:
            widget?.toolbarVisibility !== undefined
              ? widget.toolbarVisibility
              : widget?.restoreButton || widget?.saveButton // never set before
              ? "always"
              : "never"
        };
      }
    },
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    formName: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "formName", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.formName ?? "";
      }
    }
  },
  watch: {
    equipmentData(n) {
      if (n) {
        this.lastData = { ...n };
      }
    },
    control: {
      deep: true,
      handler(n, o) {
        if (!n) return;
        if (!o || n.id != o.id) {
          this.dataSourceType =
            n?.synopticComponent?.dataSource?.type || "local";
          this.dataSourceId = n?.synopticComponent?.dataSource?.id || "";
          this.buildItems();
        }
        this.commitChanges(n);
      }
    },
    controls: {
      deep: true,
      handler(val) {
        this.control = val.length ? val[0] : null;
      }
    },
    stateImages: {
      deep: true,
      handler(n) {
        this.commitChanges(this.control);
      }
    },
    dataSourceType(n, o) {
      if (n == "global") {
        if (!this.dataSourceId) this.dataSourceId = this.globalLists[0].id;
        this.$nextTick(() => {
          this.$refs.globalList.focus();
        });
      } else if (n == "data") {
        if (!this.dataSourceId)
          this.dataSourceId = this?.lastData?.text_list?.id || o;
      } else {
        if (!this.dataSourceId) this.dataSourceId = "";
      }
      this.buildItems();
    },
    dataSourceId(n) {
      this.buildItems();
    }
  },
  methods: {
    buildItems() {
      let lst = [];
      if (this.dataSourceType == "local") {
        lst = JSON.parse(
          JSON.stringify(this.control?.synopticComponent?.stateImages || [])
        );
      } else {
        let id = this.dataSourceId || 0;
        if (id) {
          let prvLst = this.control?.synopticComponent?.stateImages || [];
          let entry = this.globalLists.find((i) => i.id == id) || null;
          for (let key in entry?.items || {}) {
            lst.push({
              state: key,
              backgroundColor:
                prvLst.find(({ state }) => state == key)?.backgroundColor ||
                "transparent",
              img: "",
              label: entry.items[key]
            });
          }
        }
      }
      (lst || []).forEach((i, ix) => {
        this.enabled[ix] = false;
      });
      this.$set(
        this,
        "stateImages",
        (this.stateImages = JSON.parse(JSON.stringify(lst)))
      );
    },
    commitChanges(control) {
      this.updateControl({
        ...control,
        synopticComponent: {
          ...control.synopticComponent,
          dataSource: {
            type: this.dataSourceType,
            id: this.dataSourceId
          },
          stateImages: [...this.stateImages]
        }
      });
    },
    removeStateImage(index) {
      this.stateImages.splice(index, 1);
    },
    addStateImage() {
      let lst = (this.stateImages || []).filter(
        ({ state }) => parseInt(state) == state
      );
      var next =
        (lst || [])
          .map((i) => i.state)
          .reduce(function (a, b) {
            return Math.max(parseInt(a), parseInt(b));
          }, 0) + 1;
      this.stateImages.push({
        backgroundColor: "transparent",
        img: "",
        label: "",
        state: next
      });
      this.toggle(this.stateImages.length - 1);
      this.$nextTick(() => {
        let el = `editor-label-${this.stateImages.length - 1}`;
        this.$nextTick(() => {
          if (this.$refs[el]) this.$refs[el][0].focus();
        });
      });
    },
    toggle(ix, $event) {
      let lst = this.enabled || {};
      if ($event && $event.ctrlKey) {
        lst[ix] = !lst[ix];
      } else {
        this.stateImages.forEach((item, i) => {
          lst[i] = i == ix ? !lst[ix] : false;
        });
      }
      this.$set(this, "enabled", JSON.parse(JSON.stringify(lst)));
    },
    isEnabled(ix) {
      if (!(ix in this.enabled)) {
        return true;
      }
      return this.enabled[ix];
    },
    updateControl(control) {
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control: control,
        noMerge: true
      });
    },
    buildExpression(source) {
      this.expression = source
        .replace(/\$\('[\d]+/, "$('.")
        .replace(/\?\.name/, ".id");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    }
  },
  mounted() {
    this.control = this.controls[0];
    this.dataSourceType =
      this.control?.synopticComponent?.dataSource?.type || "local";
    this.dataSourceId = this.control?.synopticComponent?.dataSource?.id || "";
    this.buildItems();
  }
};
</script>

<style scoped>
.state-list {
  max-height: 35dvh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 60px;
}

.state-list::-webkit-scrollbar {
  width: 8px;
}

.state-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.state-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.state-item {
  /* margin-top: 1rem; */
  margin: 5px 0;
}

.state-item.open {
  /* margin-top: 1rem; */
  padding: 0 0 20px 0;
  margin-bottom: 15px;
}

.state-item:not(:last-of-type) {
  /* padding-bottom: 5px; */
  border-bottom: 1px solid #dcdcdc;
}

.form-group-min {
  margin-bottom: 0px;
}

.form-group-no-margin {
  margin-top: -14px;
  text-align: left;
}
.form-group-no-margin > select {
  padding: 0 6px;
}
.fa-btn:hover {
  cursor: pointer;
}
.fa-btn-disabled {
  color: gray;
}
.fa-btn-disabled:hover {
  cursor: not-allowed;
}
.multi-color-selector {
  margin-bottom: 0.5em;
}
.clicable:hover {
  background-color: transparent;
}
</style>
